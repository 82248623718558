<template>
    <div class="page">
        <div class="query-form p_b1">
            <div class="m_b1">
                <span class="fontSize14">下单时间</span>
                <el-date-picker class="m_l1 m_r1" size="small"
                                value-format="yyyy-MM-dd"
                                format="yyyy-MM-dd"
                                v-model="createTime"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期" style="width: 30%">
                </el-date-picker>
                <el-input class="m_r1" size="small" v-model="searchForm.keyWords" maxlength="50"
                          placeholder="联系人/联系电话/订单编号/券码" clearable style="width: 30%"></el-input>
            </div>
            <div>
                <el-select class="m_r1" clearable  v-model="searchForm.ticketingServerName" placeholder="选择服务" size="small" style="width: 21.7%">
                    <el-option
                            v-for="item in serverOpt"
                            :key="item.id"
                            :label="item.ticketingServerName"
                            :value="item.ticketingServerName">
                    </el-option>
                </el-select>
                <el-select class="m_r1" clearable  v-model="searchForm.serverOrderState" placeholder="订单状态" size="small" style="width: 21.7%">
                    <el-option
                            v-for="item in $dictUtils.getDictList('server_orderState')"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
                <el-select class="m_r1" clearable  v-model="searchForm.serverPayState" placeholder="支付状态" size="small" style="width: 21.7%">
                    <el-option
                            v-for="item in $dictUtils.getDictList('server_payState')"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
                <el-button type="primary" @click="queryData(1)" size="small"
                           icon="el-icon-search">查询
                </el-button>
                <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
                <el-button class="f_r" type="primary" :disabled="orderList.length <= 0" @click="exportData" size="small">导出</el-button>
            </div>
        </div>
        <div class="bg-white">
            <el-table :data="orderList"
                      v-loading="orderLoading"
                      size="small" ref="orderTable"
                      height="calc(100vh - 320px)" class="table">
                <el-table-column type="selection" width="50" fixed></el-table-column>
                <el-table-column prop="outTradeNo" label="订单编号" width="150" show-overflow-tooltip></el-table-column>
                <el-table-column prop="manualExplanation" label="人工讲解" show-overflow-tooltip></el-table-column>
                <el-table-column prop="embroideryExplanation" label="刺绣体验" show-overflow-tooltip></el-table-column>
                <el-table-column prop="actualPaymentPrice" label="实付款（元）" width="150" show-overflow-tooltip></el-table-column>
                <el-table-column prop="serverOrderState" label="订单状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{$dictUtils.getDictLabel("server_orderState",scope.row.serverOrderState,'')}}
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="下单时间" sortable show-overflow-tooltip
                                 width="140"></el-table-column>
                <el-table-column prop="orderPayTime" label="支付时间" sortable show-overflow-tooltip
                                 width="140"></el-table-column>
                <el-table-column prop="ticketingVerbName" label="联系人" width="140" show-overflow-tooltip></el-table-column>
                <el-table-column prop="ticketingVerbPhone" label="联系电话" width="100" show-overflow-tooltip></el-table-column>
                <el-table-column prop="authorizationPhone" label="授权电话" width="100" show-overflow-tooltip></el-table-column>
                <el-table-column fixed="right" width="100" label="操作">
                    <template slot-scope="scope">
                        <el-button v-if="hasPermission('serveOrderList:view')" icon="el-icon-view" type="text" size="mini"
                                   @click="view(scope.row)">查看
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="text_center"
                           :current-page="searchForm.current"
                           :page-size="searchForm.size"
                           :page-sizes="[10, 20, 50, 100]"
                           :total="orderTotal"
                           @size-change="sizeChangeHandle"
                           @current-change="currentChangeHandle"
                           background
                           layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <!--查看-->
        <ServeOrderView ref="serveOrderView"></ServeOrderView>
    </div>
</template>

<script>
    import ServeOrderView from './serveOrderView'

    export default {
        components: {ServeOrderView},
        data() {
            return {
                createTime: [],
                searchForm: {
                    keyWords: '',
                    createStartTime: '',
                    createEndTime: '',
                    serverOrderState: '',
                    serverPayState: '',
                    ticketingServerName: '',
                    ticketingTerminal: '',
                    current: 1,
                    size: 10,
                },
                // 订单列表
                orderList: [],
                orderLoading: false,
                orderTotal: 0,
                serverOpt: [],
            }
        },
        mounted() {
            this.queryServerData()
            this.queryData(1)
        },
        methods: {
            // 数据查询 type: 0订单；1人员
            queryData(current) {
                let param = JSON.parse(JSON.stringify(this.searchForm))
                param.createStartTime = this.createTime ? this.createTime[0] : ''
                param.createEndTime = this.createTime ? this.createTime[1] : ''
                if (current) {
                    param.current = current
                    this.searchForm.current = current
                } else {
                    param.current = this.searchForm.current
                }
                this.orderLoading = true
                console.log(param)
                this.$axios(this.api.order.sysGetTicketingServerOrder, param, 'post').then((res) => {
                    if (res.status) {
                        this.orderList = res.data.records
                        this.orderTotal = parseInt(res.data.total)
                        this.$nextTick(() => {
                            this.$refs.orderTable.doLayout();
                        })
                    } else {
                        this.$message.error('查询失败');
                    }
                    this.orderLoading = false
                })
            },
            // 数据服务查询
            queryServerData() {
                this.$axios(this.api.order.sysGetTicketingServer, {current: 1, size: 99}).then((res) => {
                    if (res.status) {
                        this.serverOpt = res.data.records
                    }
                })
            },
            // 重置
            resetSearch() {
                this.createTime = []
                this.searchForm = {
                    keyWords: '',
                    createStartTime: '',
                    createEndTime: '',
                    serverOrderState: '',
                    serverPayState: '',
                    ticketingServerName: '',
                    ticketingTerminal: '',
                    current: 1,
                    size: 10,
                }
                this.queryData(1)
            },
            // 导出
            exportData() {
                let createStartTime = this.createTime.length ? this.createTime[0] : ''
                let createEndTime = this.createTime.length ? this.createTime[1] : ''
                window.open(this.$globalUrl() + this.api.order.serverOrderExPortList + '?createStartTime=' + createStartTime + '&createEndTime=' + createEndTime + '&keyWords=' + this.searchForm.keyWords + '&serverOrderState=' + this.searchForm.serverOrderState + '&serverPayState=' + this.searchForm.serverPayState + '&ticketingServerName=' + this.searchForm.ticketingServerName + '&ticketingTerminal=' + this.searchForm.ticketingTerminal)
            },
            // 查看
            view(row) {
                this.$refs.serveOrderView.init(row)
            },
            // 订单每页数
            sizeChangeHandle(val) {
                this.searchForm.size = val;
                this.searchForm.current = 1;
                this.queryData()
            },

            // 订单当前页
            currentChangeHandle(val) {
                this.searchForm.current = val;
                this.queryData()
            },
        }

    }
</script>

<style scoped>
    .table >>> .el-table__fixed-right, .table >>> .el-table__fixed {
        height: 100% !important;
    }
</style>
